import consumer from "./consumer";

document.addEventListener('DOMContentLoaded', function () {
  const authBtn = document.getElementById('linkedin-auth-btn');

  if (!authBtn) {
    console.warn('LinkedIn auth button not found. Ensure the element ID is correct.');
    return;
  }

  const token = authBtn.dataset.token;
  const locale = authBtn.getAttribute('data-locale');
  let authWindow; // Reference to the popup window

  // Add click event to open the LinkedIn authentication popup
  authBtn.addEventListener('click', function() {
    // Open LinkedIn authentication in a popup window and save the reference
    authWindow = window.open(`/${locale}/linkedin/request_permission/${token}`, 'LinkedIn Auth', 'width=600,height=700');
  });

  // Subscribe to the LinkedIn channel using Action Cable
  consumer.subscriptions.create(
    { channel: "LinkedinChannel", token: token },
    {
      connected() {
        console.log('Connected to LinkedIn channel with token:', token);
      },

      disconnected() {
        console.log('Disconnected from LinkedIn channel');
      },

      received(data) {
        console.log('Received data:', data);

        // Check for the close_window command and close the popup if it exists
        if (data.type === 'linkedin_auth_complete' && data.event_user_token === token) {
          if (data.command === 'close_window' && authWindow) {
            console.log('Closing LinkedIn authentication window.');
            authWindow.close(); // Close the popup window

            // Update the UI after closing the window
            document.getElementById('linkedin-auth-btn').style.display = 'none';
            document.getElementById('linkedin-post-btn').style.display = 'flex';
            

            // Update the preview button to include the poster URL
            const previewBtn = document.getElementById('linkedin-post-btn');
            previewBtn.setAttribute('data-poster-url', data.poster_url);
          }
        }
      }
    }
  );

  document.getElementById('linkedin-post-btn').addEventListener('click', function() {
    const posterUrl = this.getAttribute('data-poster-url');

    // Show the modal with the poster preview
    showPostPreviewModal(posterUrl, token, locale);
  });
});


function showPostPreviewModal(posterUrl, token, locale) {
  // Display the modal with the poster and post details
  const modalContent = `
    <div class="modal" id="postPreviewModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Post Preview</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <img src="${posterUrl}" alt="Post Preview" style="width: 100%;">
            <p>Check out this amazing event!</p>
          </div>
          <div class="modal-footer">
            <button id="publish-post-btn" type="button" class="btn btn-primary">Publish</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  `;

  // Append modal to body and show it
  document.body.insertAdjacentHTML('beforeend', modalContent);
  $('#postPreviewModal').modal('show');

  // Handle publishing the post
  document.getElementById('publish-post-btn').addEventListener('click', function() {
    // Implement the publishing logic here
    this.disabled = true;
    this.innerText = 'Publishing...';

    fetch(`/${locale}/linkedin/post_to_linkedin?token=${token}`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': '<%= form_authenticity_token %>',
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        document.getElementById('publish-post-btn').innerText = 'Published';
      } else {
        document.getElementById('publish-post-btn').innerText = 'Failed to Publish';
      }
    })
    .catch(error => {
      document.getElementById('publish-post-btn').innerText = 'Error';
      console.error('Error:', error);
    });
  });
}